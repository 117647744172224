import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { declineOffer, acceptOffer } from '../../../../actions/userActions';
import { Container, Heading } from './styled';
import MessageList from './MessageList';
import CurrentMessage from './CurrentMessage';

function Messages({
  messages:_messages, accountType, accountData, user, declineOffer, acceptOffer,
}) {
  const { profile } = accountData || {};
  let messages = _messages;

  if (accountType === 2) {
    Object.keys(messages).forEach(userKey => {
      let { thread } = messages[userKey];
      messages[userKey].thread = thread.filter(({ type }) => type ==='message');
    });
  }

  const [currentMessage, setCurrentMessage] = useState(null);
  const [search, setSearch] = useState({});

  useEffect(() => {
    if (!currentMessage && Object.keys(messages).length) {
      setCurrentMessage(Object.values(messages)[0].thread.slice(-1)[0]);
    }
    setSearch(messages);
  }, [currentMessage, messages]);

  const currentUser = Object.values(search)[0];
  return (
    <Container>
      <Heading>Inbox</Heading>
      <div style={{ display: 'flex' }}>
        <MessageList
          profile={profile}
          searchResults={search}
          setSearch={setSearch}
          selectMessage={setCurrentMessage}
          currentMessageId={currentMessage && currentMessage.id}
          messages={messages} />
        {currentMessage && <CurrentMessage acceptOffer={acceptOffer} declineOffer={declineOffer} user={user} profile={profile} accountType={accountType} {...currentUser} />}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { acceptOffer, declineOffer };

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
