import React from 'react';
import { connect } from 'react-redux';
import { setSortByFilter } from '../../../../../actions/shopActions';
import {
  MenuItemContainer, MenuLabel, Line, MenuItem, OptionsContainer,
} from './styled';
import DownArrowIcon from './DownArrow.svg';

function SortByMenu({
  label, options, setSortByFilter,
}) {
  return (
    <MenuItemContainer>
      <MenuItem>
        <MenuLabel>{label}:</MenuLabel>
        <OptionsContainer>
          {options && (<select onChange={({ target: { value } }) => setSortByFilter(value)}>
            {
              options.map((option_key) => {
                if (option_key.length > 14) {
                  option_key = option_key.substr(0, 12) + '...';
                }
                return <option key={option_key}>{option_key}</option>;
              })
            }
          </select>)}
          <img src={DownArrowIcon} />
        </OptionsContainer>
      </MenuItem>
      <Line />
    </MenuItemContainer>
  );
}

const mapDispatchToProps = { setSortByFilter };

export default connect(null, mapDispatchToProps)(SortByMenu);
