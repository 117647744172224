import React from 'react';
import Column from '../../../Column';
import H3 from '../../../Text/H3';
import {
  Container, Section, Details,
  Title, Text, HowToBuy, HowToBuySection,
  HowToBuyTitle, HowToBuyContent, HowToBuySplitContainer,
  HowToBuySplitLine, HowToBuySplitCenter,
} from './styled';

function Section2({
  silhouette, designer, name, __id, material,
  neckline, condition, tagSize, streetSize, story, history, alterations,
  sellerId,color,
}) {
  return (
    <Container>
      <Section>
        <Title>Details:</Title>
        <Details>
          <Text>Silhouette:</Text><Text>{silhouette}</Text>
          <Text>Designer:</Text><Text>{designer}</Text>
          <Text>Name:</Text><Text>{name}</Text>
          <Text>Listing Number:</Text><Text>{__id}</Text>
          <Text>Material:</Text><Text>{material}</Text>
          <Text>Color:</Text><Text>{color}</Text>
          <Text>Neckline:</Text><Text>{neckline}</Text>
          <Text>Condition:</Text><Text>{condition}</Text>
          <Text>Tag Size:</Text><Text>{tagSize}</Text>
          <Text>Street Size:</Text><Text>{streetSize}</Text>
          {sellerId && (
            <>
              <Text>Boutique Seller #:</Text>
              <Text>{sellerId}</Text>
            </>
          )}
        </Details>
        {alterations && <Column style={{ margin: '30px 25px' }}>
          <H3 style={{ textAlign:'center' }}>Alterations:</H3>
          <Text>{alterations}</Text>
        </Column>}
        {history && <Column style={{ margin: '30px 25px' }}>
          <H3>Preservation History:</H3>
          <Text>{history}</Text>
        </Column>}
      </Section>
      <Section style={{ marginTop: 20 }}>
        <Title>The Story:</Title>
        <Text style={{ padding: '0 25px' }}>
          {story}
        </Text>
      </Section>
      <Section style={{ marginTop: 20 }}>
        <Title>How To Buy:</Title>
        <HowToBuy>
          <HowToBuySection>
            <HowToBuyTitle>Option 1: Buy the dress in an instant!</HowToBuyTitle>
            <HowToBuyContent>Hit the buy now button above and the dress will be yours!</HowToBuyContent>
          </HowToBuySection>
          <HowToBuySplit />
          <HowToBuySection>
            <HowToBuyTitle>Option 2: Send a Message</HowToBuyTitle>
            <HowToBuyContent>Start a conversation with the buyer & discuss the details of the garment.</HowToBuyContent>
          </HowToBuySection>
        </HowToBuy>
      </Section>
    </Container>
  );
}

export default Section2;



function HowToBuySplit() {
  return (
    <HowToBuySplitContainer>
      <HowToBuySplitLine />
      <HowToBuySplitCenter>or</HowToBuySplitCenter>
      <HowToBuySplitLine />
    </HowToBuySplitContainer>
  );
}