import React from 'react';
import { connect } from 'react-redux';
import { Container } from './styled';
import SortByMenu from './SortByMenu';
import TagSizeMenu from './TagSizeMenu';
import BoutiqueMenu from './BoutiqueMenu';
import PriceRangeMenu from './PriceRangeMenu';

function getMenuComponents(menuOption) {
  switch (menuOption.id) {
  case 'streetSize': return <TagSizeMenu {...menuOption} />;
  case 'price_range': return <PriceRangeMenu {...menuOption} />;
  case 'tagSize': return <TagSizeMenu {...menuOption} />;
  case 'boutique': return <BoutiqueMenu {...menuOption} />;
  case 'sort_by': return <SortByMenu {...menuOption} />;
  }
}

function getBoutiquesFromListings(products){
  return Object.values(products).map(({ boutique }) => boutique).filter((exists) => !!exists);
}

function Section1({ products }) {
  const boutiques = getBoutiquesFromListings(products);
  const menu_items = [
    {
      id: 'sort_by',
      label: 'SORT BY',
      options: ['Most Popular', 'On Sale', 'Price - High to Low', 'Price - Low to High'],
    },
    {
      id: 'tagSize',
      label: 'TAG SIZE',
      options: [...Array(16).fill(0).map((_, index) => `${index * 2}`)],
    },
    {
      id: 'streetSize',
      label: 'STREET SIZE',
      options: [...Array(16).fill(0).map((_, index) => `${index * 2}`)],
    },
    {
      id: 'boutique',
      label: 'BOUTIQUE',
      options: boutiques,
      defaultText: 'Select a boutique',
    },
    {
      id: 'price_range',
      label: 'PRICE RANGE',
      options: [[...Array(21).fill(0).map((_, index) => (50000 * index))], [...Array(21).fill(0).map((_, index) => (50000 * index))]],
    },
  ];
  return (
    <Container>
      {
        menu_items.map(getMenuComponents)
      }
    </Container>
  );
}


const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps)(Section1);
