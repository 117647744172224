import React from 'react';
import Row from '../../../../Row';
import RadioButton from '../../../../RadioButton';
import { Finalizing } from './styled.js';
import MoreInformation from './MoreInformation';

function Insurance({ form, updateForm }) {
  return (
    <>
      <Row style={{ margin: '30px auto 10px' }}>
        <Finalizing>Before finalizing your order, would you like to purchase So Bridely Dress Insurance?* <i>(Required Field)</i></Finalizing>
        <RadioButton style={{ margin: '0 15px' }} label="Yes" value={form.purchaseInsurance} update={() => updateForm('purchaseInsurance', true)} />
        <RadioButton label="No" value={!form.purchaseInsurance} update={() => updateForm('purchaseInsurance', false)} />
      </Row>
      <MoreInformation />
    </>
  );
}

export default Insurance;