import React from 'react';
import { getDocument } from '../helpers/Firebase';
import { getUUID } from '../helpers/Utils';
// import OfferNotification from '../components/Notifications/OfferNotificationSeller.js';
import OfferNotificationUser from '../components/Notifications/OfferNotificationUser.js';
import * as types from './actionTypes';
import { addItemToCart } from './checkoutActions';

export function loadNotifications() {
  return (dispatch, getState) => {
    const { offers } = getState().userReducer;

    offers.forEach((offer) => {
      const {
        listing, id, status,
      } = offer;

      if (status !== 'pending') {
        dispatch(addNotification({
          component: <OfferNotificationUser {...offer}/>,
          title: status === 'accepted' ? 'Congratulations' : `We're Sorry`,
          subTitle: `Your offer was ${status}`,
          callback: status === 'accepted' ? () => dispatch(addItemToCart(listing)) : null,
          id,
        }));
      }
    });
  };
}

export function addNotification({
  component, title, subTitle, callback, id,
}) {
  return {
    type: types.ADD_NOTIFICATION,
    component, title, subTitle, callback, id: id || getUUID(),
  };
}

export function removeNotification(id) {
  return {
    type: types.REMOVE_NOTIFICATION,
    id,
  };
}

export function loadQuestions() {
  return async (dispatch, getState) => {
    const { user: { uid } } = getState().userReducer;
    const { products } = getState().shopReducer;
    const sellerProducts = Object.values(products).filter(({ seller }) => seller === uid);
    let sellerQuestions = [];

    sellerProducts.forEach(({ questions:_questions, __id }) => {
      let __questions = _questions.map((data) => ({
        ...data,
        listing: __id,
      })).filter(({ answer }) => !answer);
      sellerQuestions = sellerQuestions.concat(__questions);
    });

    const questionsFormatted = await formatQuestions(sellerQuestions);

    dispatch({
      type: types.LOAD_QUESTIONS,
      questions: questionsFormatted,
    });
  };
}

export function loadOffersFromUser(){
  return async (dispatch, getState) => {
    const { messages } = getState().userReducer;
    let offers = [];

    Object.values(messages).forEach(({ thread }) =>
      thread.forEach(({
        type, listing, id,
      }) => {
        if (type === 'offer') {
          const { products } = getState().shopReducer;
          const product = Object.values(products).find(({ __id }) => __id == listing);

          if (product) {
            const offer = product.offers.find(({ id: offerId }) => offerId == id);

            offers.push({
              ...offer,
              listing:product.__id,
            });
          }
        }
      }),
    );

    const offersFormatted = await formatOffers(offers);

    dispatch({
      type: types.LOAD_OFFERS,
      offers: offersFormatted,
    });
  };
}

export function loadOffersFromSeller() {
  return async (dispatch, getState) => {
    const { user: { uid } } = getState().userReducer;
    const { products } = getState().shopReducer;
    const sellerProducts = Object.values(products).filter(({ seller }) => seller === uid);
    let sellerOffers = [];

    sellerProducts.forEach(({ offers:_offers = [], __id }) => {
      let __offers = _offers.map((data) => ({
        ...data,
        listing: __id,
      })).filter(({ status }) => status == 'pending');
      sellerOffers = sellerOffers.concat(__offers);
    });

    const offersFormatted = await formatOffers(sellerOffers);

    dispatch({
      type: types.LOAD_OFFERS,
      offers: offersFormatted,
    });
  };
}

function formatOffers(offers) {
  const promises = [];

  offers.forEach(({
    amount,
    date,
    id,
    user:userId,
    listing,
    status,
  }) => {
    promises.push(new Promise((resolve) => {
      getDocument(`users/${userId}`).then((user) => {
        const {
          profile: {
            firstName, lastName, avatar,
          } = {},
        } = user;

        resolve({
          firstName, lastName, amount, avatar, listing, id, date, status,
        } );
      });
    }));
  });
  return Promise.all(promises);
}


function formatQuestions(questions) {
  const promises = [];

  questions.forEach(({
    question,
    date,
    id,
    user:userId,
    listing,
  }) => {
    promises.push(new Promise((resolve) => {
      getDocument(`users/${userId}`).then((user) => {
        const {
          profile: {
            firstName, lastName, avatar,
          } = {},
        } = user;

        resolve({
          firstName, lastName, question, avatar, listing, id, date,
        } );
      });
    }));
  });
  return Promise.all(promises);
}