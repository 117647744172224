import React from 'react';
import { connect } from 'react-redux';
import {
  Heading, Line, Title,
} from '../ShopBy/styled';
import Item from '../../../Item';
import { Container, Dresses } from './styled.js';

function Featured({ products, featured_wedding_dresses }) {
  if (!Object.keys(products).length) {
    return <div />;
  }
  return (
    <Container>
      <Heading>
        <Line style={{ marginLeft: 125, marginRight: 40 }} />
        <Title>{featured_wedding_dresses}</Title>
        <Line style={{ marginRight: 125, marginLeft: 40 }} />
      </Heading>
      <Dresses>
        {Object.values(products).map((item) => <Item key={item.__id} {...item} />)}
      </Dresses>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps)(Featured);