import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { signOut } from '../../../helpers/Firebase';
import NavLogo from '../../NavLogo';
import Flexor from '../../Flexor';
import Image from '../../ImageBasic';
import {
  Container, CartItems, CartImage,
} from './styled';
import MenuItem from './MenuItem';
import CartIcon from './CartIcon.svg';

function NavigationBar({
  user, checkoutItems, accountType,
}) {
  return (
    <Container>
      {
        <>
          <Flexor flex="0 1 43px" />
          {/* <Image onClick={() => navigate('/')} src={} /> */}
        </>
      }
      <MenuItem
        label="Buy"
        expandedOptions={[
          { label: 'All Dresses', link: '/shop' },
          { label: 'Recently Added', link: '/shop?search=recent' },
          { label: 'Shop By Boutiques', link: '/shop?search=boutique' },
        ]}
      />
      <MenuItem
        label="Sell"
        expandedOptions={[
          { label: 'For Brides', link: '/create-account/seller' },
          { label: 'For Boutiques', link: '/create-account/seller#boutique' },
        ]}
      />
      <MenuItem
        label="How it Works"
        link="/how-it-works"
      />
      <NavLogo style={{ marginRight: 0 }} />
      {/* <MenuItem
        label="Blog"
        link="/blog" /> */}
      <MenuItem
        label="Help"
        link="mailto:help@sobridely.com"
      />
      <MenuItem
        label="About Us"
        link="/about-us"
      />
      {user ?
        (accountType === 2 ? (
          <MenuItem
            label="My Account"
            expandedOptions={[
              { label: 'Profile', link: '/dashboard#profile' },
              { label: 'Inbox', link: '/dashboard#inbox' },
              { label: 'Fitting Room', link: '/dashboard#fitting-room' },
              { label: 'Order History', link: '/dashboard#orders' },
              { label: 'Logout', action: signOut },
            ]}
          />
        ) : (<MenuItem
          label="My Account"
          expandedOptions={[
            { label: 'Profile', link: '/dashboard#profile' },
            { label: 'Inbox', link: '/dashboard#inbox' },
            { label: 'Notifications', link: '/dashboard#notifications' },
            { label: 'Fitting Room', link: '/dashboard#fitting-room' },
            { label: 'My Listings', link: '/dashboard#my-listings' },
            { label: 'Order History', link: '/dashboard#orders' },
            { label: 'Logout', action: signOut },
          ]}
        />))
        :
        <MenuItem
          label="Login"
          link="/login"
        />}
      <div style={{ position: 'relative', marginLeft: 43 }}>
        <CartImage onClick={() => navigate('/checkout')} src={CartIcon} />
        {Object.keys(checkoutItems).length ? <CartItems>{Object.keys(checkoutItems).length}</CartItems> : null}
      </div>
      <Flexor flex="0 1 43px" />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  ...state.userReducer,
  ...state.shopReducer,
});

export default connect(mapStateToProps)(NavigationBar);
