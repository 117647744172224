import { navigate } from '@reach/router';
import * as apiHelpers from '../helpers/API';
import { removeStorage } from '../helpers/LocalStorage';
import * as loadingActions from './loadingActions';
import * as types from './actionTypes';

export function createPurchase(form) {
  return async (dispatch, getState) => {
    const { ccToken } = form;

    if (!ccToken) {
      return alert('Please enter a valid credit card.');
    }

    const {
      shippingCity, shippingStreet1, shippingStreet2,
      shippingState, shippingZip, total,
    } = form;
    const {
      checkoutItems, shipmentId, rateId,
    } = getState().shopReducer;
    const { user: { uid } } = getState().userReducer;
    const shippingAddress = {
      city: shippingCity,
      state: shippingState,
      street1: shippingStreet1,
      street2:shippingStreet2,
      zip: shippingZip,
      country: 'US',
    };
    const listings = Object.keys(checkoutItems);
    dispatch(loadingActions.setLoading(true));
    const res = await apiHelpers.createPurchase({
      listings,
      user: uid,
      amount: total,
      address: shippingAddress,
      shipmentId,
      rateId,
      token: ccToken,
    });
    dispatch(loadingActions.setLoading(false));

    if (res === 'OK') {
      alert('Payment completed!');
      dispatch(clearCheckout());

      await navigate('/');
    } else {
      alert('There was a problem processing your payment');
    }
  };
}

export function clearCheckout() {
  return dispatch => {
    dispatch(setCartItems());
    dispatch({ type: types.CLEAR_SHIPPING_DETAILS });
    removeStorage('checkoutItems');
  };
}

export function addItemToCart(itemId) {
  return dispatch => {
    dispatch({
      type: types.ADD_ITEM_TO_CART,
      itemId,
    });
    navigate('/checkout');
  };
}

export function setCartItems(checkoutItems) {
  return {
    type: types.SET_CART_ITEMS,
    checkoutItems,
  };
}

export function editCartItem(itemId, quantity) {
  return {
    type: types.EDIT_CART_ITEM,
    itemId,
    quantity,
  };
}

export function setShippingDetails(rate, shipmentId, rateId) {
  return {
    type: types.SET_SHIPPING_DETAILS,
    shippingCost: rate,
    shipmentId,
    rateId,
  };
}