import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container } from './styled';
//helpers
import Section1 from './Section1';
import Section2 from './Section2';

const staticQuery = graphql`
query {
 footer_data: allWordpressPage {
  edges {
    node {
      acf {
        footer_email_list_heading
        left_content {
          heading
          content
        }
        footer_email_list_input {
          input_1
          input_2
        }
        middle_content {
          heading
          content
        }
        right_content
        footer_navigation_bar {
          copyright
          menu_items {
            menu_item
          }
        }
      }
    }
  }
}
}
`;
const Footer = () => (
  <StaticQuery
    query={staticQuery}
    render={data => (
      <Container>
        <Section1 />
        <Section2 />
      </Container>
    )}
  />
);

export default Footer;
