import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import Row from '../../../Row';
import H1 from '../../../Text/H1';
import Image from '../../../ImageBasic';
import {
  editListing, boostListing, createNewListing, publishListings,
} from '../../../../actions/userActions';
import Button1 from '../../../Button1';
import {
  Container, Heading, Body, NewItemContainer,
  Note,
} from './styled';
import ListingDetails from './ListingDetails';
import NewDressSVG from './NewDressIcon.svg';
import Item from './Item';

function MyListings({
  listings: publishedListings, unpublishedListings = [], editListing, boostListing,
  createNewListing, accountType, accountData, publishListings,
}) {
  const publish = useCallback((listingsToPublish) => {
    if (unpublishedListings.length === 0) {
      alert('All listings already published');
    } else {
      publishListings(listingsToPublish);
    }
    setSelectMode(false);
    setSelected({});
  }, [publishListings, unpublishedListings.length]);
  const [selected, setSelected] = useState({});
  const { packageType } = accountData || {};
  const [selectMode, setSelectMode] = useState(false);
  const [view, setView] = useState('listings');
  const [selectedListing, setSelectedListing] = useState(null);
  const listings = [...publishedListings, ...unpublishedListings];
  let content = <div />;

  if (view === 'listings') {
    content = (
      <Row css={css`
        > * {
          margin-right: 20px !important;
          margin-bottom: 20px !important;
        }
      `} style={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        <NewItemContainer onClick={() => {
          setView('create');
        }}
        >
          <Image style={{ marginBottom: 43 }} src={NewDressSVG} />
          <H1 color="#6B969D">Create Listing</H1>
        </NewItemContainer>
        {
          listings.map((item_data, index) => (
            <Item disabled={publishedListings.find(({ __id }) => __id === item_data.__id) && selectMode} selected={selected[item_data.__id]} style={{ marginRight: 0 }} showBoost={packageType === 1} accountType={accountType} boost={boostListing} edit={() => {
              if (!selectMode) {
                setSelectedListing(item_data);
                setView('edit');
              } else {
                const _selected = { ...selected };
                _selected[item_data.__id] = !_selected[item_data.__id];
                setSelected(_selected);
              }
            }} key={index} {...item_data}
            />
          ))
        }
      </Row>
    );
  } else if (view === 'create') {
    content = (
      <ListingDetails buttonText="Create" packageType={packageType} accountType={accountType} close={() => setView('listings')} onSubmit={(form) => {
        createNewListing(form).then(() => {
          setView('listings');
        });
      }}
      />
    );
  } else if (view === 'edit') {
    content = (<>
      <Item showBoost={packageType === 1} accountType={accountType} boost={boostListing} {...selectedListing} />
      <ListingDetails buttonText="Save" title="Edit Listing" packageType={packageType} accountType={accountType} onSubmit={(listing) => {
        editListing(listing.__id, listing);
      }} close={() => setView('listings')} {...selectedListing}
      />
    </>);
  }

  return (
    <Container>
      <Heading>
        <div>My Listings</div>
        {view === 'listings' && (
          <Row style={{ margin: '0 auto' }}>
            <Button1
              onClick={() => {
                setSelectMode(!selectMode);
                setSelected({});
              }}
              style={{
                marginRight: 15, height: 50, width:130,
              }} color={ selectMode ? '#EAAFB7' : '#7DBEC8'}
            >{selectMode ? 'Cancel' : 'Select'}</Button1>
            {selectMode ? <Button1 onClick={() => publish(Object.keys(selected).filter((id) => selected[id]))} style={{ height: 50, width:130 }} color="#7DBEC8">Publish</Button1> :
              <Button1 onClick={() => publish(unpublishedListings.map(({ __id }) => __id))} style={{ height: 50, width:130 }} color="#7DBEC8">Publish All</Button1>}
          </Row>
        )}
      </Heading>
      {view === 'listings' && <Note>Note: All listings will expire 12 months from the date of creation. Expiring listings will need to be renewed.</Note>}
      <Body>
        {content}
      </Body>
    </Container>
  );
}

const mapDispatchToProps = {
  editListing, boostListing, createNewListing, publishListings,
};

export default connect(null, mapDispatchToProps)(MyListings);
