import React from 'react';
import { connect } from 'react-redux';
import { Container } from './styled';
import SortByMenu from './SortByMenu';
import TagSizeMenu from './TagSizeMenu';
import PriceRangeMenu from './PriceRangeMenu/';
import BoutiqueMenu from './BoutiqueMenu';

function getMenuComponents(menuOption, index) {
  switch (menuOption.id) {
  case 'streetSize': {
    return <TagSizeMenu index={index} {...menuOption}/>;
  }
  case 'tagSize': {
    return <TagSizeMenu index={index} {...menuOption}/>;
  }
  case 'boutique': {
    return <BoutiqueMenu index={index} {...menuOption}/>;
  }
  case 'sort_by': return <SortByMenu index={index} {...menuOption} />;
  case 'price_range': return <PriceRangeMenu index={index} {...menuOption} />;
  }
}

function getBoutiquesFromListings(products){
  return Object.values(products).map(({ boutique }) => boutique).filter((exists) => !!exists);
}

const MAX_SIZES = 30;

function Section1({ products }) {
  const boutiques = getBoutiquesFromListings(products);
  const menu_items = [
    {
      id: 'sort_by',
      label: 'SORT BY',
      options: [{ label: 'Most Popular', id: 'popular' }, { label: 'On Sale', id: 'sale' }, { label: 'Price - High to Low', id: 'high-low' }, { label: 'Price - Low to High', id: 'low-high' }],
      defaultText: 'Sort by',
    },
    {
      id: 'tagSize',
      label: 'TAG SIZE',
      options: [...Array((MAX_SIZES + 2) / 2).fill(0).map((_, index) => `${index * 2}`)],
    },
    {
      id: 'streetSize',
      label: 'STREET SIZE',
      options: [...Array((MAX_SIZES + 2) / 2).fill(0).map((_, index) => `${index * 2}`)],
    },
    {
      id: 'boutique',
      label: 'BOUTIQUE',
      options: boutiques,
      defaultText: 'Search for a boutique',
    },
    {
      id: 'price_range',
      label: 'PRICE RANGE',
    },
  ];

  return (
    <Container>
      {
        menu_items.map((item, index) => getMenuComponents(item, index))
      }
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer });

export default connect(mapStateToProps)(Section1);
