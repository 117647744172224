import React from 'react';
import { navigate } from '@reach/router';
import { Container } from './styled';
import SideBar from './SideBar';
import Profile from './Profile';
import Messages from './Messages';
import Notifications from './Notifications';
import FittingRoom from './FittingRoom';
import MyListings from './MyListings';
import OrderHistory from './OrderHistory';
import MyPackage from './MyPackage';
import PackageDetails from './PackageDetails';

function Dashboard(props){
  let content = <div />;
  let page;

  if (typeof(window) !== 'undefined') {
    page = window.location.hash.replace('#', '');
  }

  if (!page) {
    navigate('/dashboard#profile');
  }

  const { products, accountData } = props || {};
  const { listings = [], unpublishedListings = [] } = accountData || {};
  const _listings = listings.map((id) => products[id]).filter((exists) => !!exists);
  const _unpublishedListings = Object.keys(unpublishedListings).map((id) => ({
    ...unpublishedListings[id], __id:id, unpublished: true,
  }));

  switch (page) {
  default:
  case 'profile': content = <Profile {...props} />;
    break;
  case 'inbox': content = <Messages />;
    break;
  case 'notifications': content = <Notifications {...props} />;
    break;
  case 'fitting-room': content = <FittingRoom />;
    break;
  case 'my-listings': content = <MyListings listings={_listings} unpublishedListings={_unpublishedListings} {...props} />;
    break;
  case 'orders': content = <OrderHistory />;
    break;
  case 'package': content = <MyPackage {...props} />;
    break;
  case 'package-details': content = <PackageDetails {...props} />;
    break;
  }
  return (
    <Container>
      <SideBar
        changePage={(pageType) => navigate(`/dashboard#${pageType}`)}
        pageIndex={page}
      />
      {content}
    </Container>
  );
}

export default Dashboard;
