import styled from 'styled-components';

export const Unpublished = styled.div`
  font-size: 10px;
  height: 15px;
  background-color: grey;
  width: 100%;
  text-align: center;
  opacity: .5;
  font-family: sofia-pro, sans-serif;
`;

export const ItemsContainer = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3.000vw 1.250vw;
  margin-right: 1.688vw;
  width: 71.88vw;
`;

export const ItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(69,73,91,0.08);
  width: 300px;
  margin-right: 30px;
  max-height: 560px;
  height: fit-content;
  :hover {
    background: #DBE6E4;
  }
  cursor: ${props => props.disabled ? 'not-allowed' : null};
  border: ${props => props.selected ? '2px solid #EAAFB7' : null};
`;

export const ItemHeader = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-direction: column;
`;

export const New = styled.div`
  width: 80px;
  background-color: #7174A8;
  height: 100%;
  align-self: flex-start;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const ItemTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
`;

export const ItemPicture = styled.div`
  background-color: #EDE7E3;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
    width: 100%;
    max-height: 300px;
    margin: 0 auto;
  }
`;

export const ItemDetailsContainer = styled.div`
  background-color: white;
  height: 68px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 14px;
`;

export const ItemPrice = styled.div`
  text-decoration: line-through;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #6B969D;
  letter-spacing: 0;
  text-align: right;
`;

export const ItemSalePrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  text-align: right;
`;

export const ItemListNumber = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ItemCondition = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 24px;
`;

export const LoadMoreButton = styled.div`
  margin: 0 auto;
  margin-top: 6.125vw;
  background: #FE95A2;
  font-size: 0.8125vw;
  color: white;
  letter-spacing: 0.06250vww;
  line-height: 1.125vw;
  border-radius: 0.6250vw;
  height: 2.875vw;
  width: 18.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 600;
  font-size: 14px;
  color: #FE95A2;
  letter-spacing: 0;
`;

export const ItemFooterHeading = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 30px;
  color: #7DBEC8;
  letter-spacing: 2.21px;
  text-align: center;
`;

export const ItemFooterContent = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14.23px;
  color: #124C56;
  letter-spacing: 0.79px;
  text-align: center;
  text-transform: uppercase;
`;