import React, { useState } from 'react';
import { connect } from 'react-redux';
import NavLogo from '../../../NavLogo';
import StandardButton from '../../../StandardButton';
import LoginFirst from '../SendAMessage/LoginFirst';
import { formatNumberInput } from '../../../../helpers/Format/numbers';
import { hideModal } from '../../../../actions/modalActions';
import {
  Container, Title, Body, Input, SubTitle,
} from './styled';

function MakeOffer({
  onSubmit, user , hideModal,
}) {
  const [offer, setOffer] = useState(0);
  const [page, setPage] = useState(0);

  if (!user || user && user.isAnonymous) {
    return <LoginFirst title="Log In First to Make A Offer" />;
  }
  return (
    <Container>
      <NavLogo style={{ color: 'black', marginTop: '7.563vw' }} color="#000000" />
      { page === 0 &&
      <Body>
        <Title>Enter Your Offer</Title>
        <Input value={`$${offer}`} onChange={(e) => setOffer(formatNumberInput(e.target.value || 0))} />
        <StandardButton style={{ marginBottom: '5.188vw', marginTop: '1.625vw' }} label="Make Offer" onClick={() => onSubmit(offer).then((res) => {
          if (res) {
            setPage(1);
          }
        })} />
      </Body>
      }
      { page === 1 &&
              <Body>
                <Title>Offer Sent!</Title>
                <SubTitle>
                  To check the progress of your offer go to your Notifications.
                </SubTitle>
                <StandardButton style={{ marginBottom: '5.188vw', marginTop: '1.625vw' }} label="Keep Shopping" onClick={() => hideModal()} />
              </Body>
      }
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });
const mapDispatchToProps = { hideModal };

export default connect(mapStateToProps, mapDispatchToProps)(MakeOffer);
