function backspaceListener(event) {
  window.GLOBAL_BACKSPACE = event.keyCode === 8;
}

function updateBackspaceForInput() {
  const currentElement = document.activeElement;

  if (currentElement.tagName.toLowerCase() === 'input') {
    currentElement.addEventListener('keydown', backspaceListener);
  }
}

export function leadingZero(n) {
  n = parseInt(n);
  return (n < 10) ? ('0' + n) : n;
}

export function formatAmount(amount, to_fixed = 2) {
  const amountParsed = parseFloat(amount / 100).toFixed(to_fixed);
  return amountParsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberInput(value) {
  value = value + '';
  value = parseInt(value.replace('$', '').replace(/[^0-9]/g, '')) || 0;
  let formattedValue = parseFloat(value / 100).toFixed(2);
  formattedValue = numberWithCommas(formattedValue);
  return formattedValue;
}

export function numberWithCommas(x) {
  x = x || 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function updatePhoneNumberInput(phoneNumber) {
  updateBackspaceForInput();
  const replace3 = str => str.replace(/(\d{3})/, '$1-');
  const replace7 = str => str.replace(/(\d{3})(\d{3})/, '$1-$2-');

  if (window.GLOBAL_BACKSPACE) {
    if (
      /\D/gi.test(phoneNumber[phoneNumber.length - 1]) ||
      phoneNumber.length === 3 ||
      phoneNumber.length === 6
    ) {
      //last char is not a digit
      return phoneNumber.slice(0, -1);
    } else {
      return phoneNumber;
    }
  }
  phoneNumber = phoneNumber.replace('/', ' ').replace(/\D+/g, '');

  if (phoneNumber[0] == 1) {
    phoneNumber = phoneNumber.slice(1);
  }

  let phoneNumber2 = phoneNumber.substr(0, 10);

  if (phoneNumber2.length >= 7) {
    return replace7(phoneNumber2);
  } else if (phoneNumber2.length >= 3) {
    return replace3(phoneNumber2);
  } else {
    return phoneNumber;
  }
}

export function updateCCExp(val = '') {
  updateBackspaceForInput();
  val = val.replace(/[^0-9|(/)]/g, '');
  const replace2 = str => str.replace(/(\d{2})/, '$1/');
  const replace4 = str => str.replace(/(\d{2})(\d{2})/, '$1/$2');

  if (window.GLOBAL_BACKSPACE) {
    if (
      /\D/gi.test(val[val.length - 1]) ||
      val.length === 3
    ) {
      //last char is not a digit
      return val.slice(0, -1);
    } else {
      return val;
    }
  }

  let val2 = val
    .replace('/', ' ')
    .replace(/\D+/g, '')
    .substr(0, 4);

  if (val2.length >= 4) {
    return replace4(val2);
  } else if (val2.length >= 2) {
    return replace2(val2);
  } else {
    return val;
  }
}

export function updateCCInput(cc = '') {
  updateBackspaceForInput();
  cc = cc.replace(/\D+/g, '');
  const replace4 = str => str.replace(/(\d{4})/, '$1-');
  const replace8 = str => str.replace(/(\d{4})(\d{4})/, '$1-$2-');
  const replace12 = str => str.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-');
  const replace16 = str =>
    str.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

  if (cc.length >= 16) {
    return replace16(cc.substr(0, 16));
  } else if (cc.length >= 12) {
    cc = replace12(cc);
  } else if (cc.length >= 8) {
    cc = replace8(cc);
  } else if (cc.length >= 4) {
    cc = replace4(cc);
  }

  if (window.GLOBAL_BACKSPACE) {
    if (cc.length === 5 || cc.length === 10 || cc.length === 15) {
      //last char is not a digit
      cc = cc.slice(0, -1);
    }
  }
  return cc;
}