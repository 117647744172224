import React, { useState } from 'react';
import moment from 'moment';
import Row from '../../../../Row';
import Button1 from '../../../../Button1';
import { sendMessage } from '../../../../../helpers/API';
import {
  Container, Name, Thread, Message, MessageInput, Send, Time, Input,
} from './styled';

function getLastMessage(message, type, name) {
  if (type === 'offer') {
    return `Hi ${name}! You have an offer on your dress for $${message}. Would you like to accept or decline this offer?`;
  } else {
    return message;
  }
}

function CurrentMessages({
  name, uid, thread = [], avatar, accountType, profile, user, acceptOffer, declineOffer,
}) {
  const [text, setText] = useState(null);
  return (
    <Container>
      <Name>{name}</Name>
      <Thread>
        {thread.sort((a, b) => a.date - b.date).map(({
          message, date, from, type, id, listing,
        }, index) => (
          <Message key={index}>
            <img src={from === user.uid ? profile.avatar : avatar} />
            <div>
              <div>{from === user.uid ? `${profile.firstName} ${profile.lastName}` : name}</div>
              <div>{getLastMessage(message, type, profile.firstName)}</div>
              <Row style={{ width: '50%' , marginTop: 20 }}>
                <Button1 onClick={() => acceptOffer(listing, id)} style={{ width:100, height: 44 }} color="#7DBEC8">ACCEPT</Button1>
                <Button1 onClick={() => declineOffer(listing, id)} style={{ width:100, height: 44 }} color="#7DBEC8">DENY</Button1>
              </Row>
            </div>
            <Time>{moment(date).fromNow()}</Time>
          </Message>
        ))}
      </Thread>
      <MessageInput>
        <Input value={text} onChange={({ target: { value: text } }) => setText(text)} />
        <Send onClick={() => {
          if (text) {
            sendMessage(uid, accountType, text);
            setText('');
          }
        }}>Send</Send>
      </MessageInput>
    </Container>
  );
}

export default CurrentMessages;
