import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//containers
import DesktopItem from '../components/Desktop/Item';
import MobileItem from '../components/Mobile/Item';
//components
import Layout from '../components/Layout';
//actions
import * as apiHelpers from '../helpers/API';
import { setMetaTags } from '../actions/homeActions';
import { showQuestionModal } from '../actions/itemsActions';
import './style.css';
import Loader from '../components/Loader';
import { formatAmount } from '../helpers/Format';

function Item({
  products, setMetaTags, ...props
}) {
  let listingId;

  if (typeof(window) !== 'undefined') {
    listingId = window.location.pathname.replace(/.*item/, '').replace('/', '');
  }

  const product = products[listingId];

  useEffect(() => {
    if (listingId) {
      apiHelpers.viewListing(listingId);
    }
  }, [listingId]);

  useEffect(() => {
    if (product) {
      const description = product.story;
      const image = product.photos[0];
      const url = window.location.href;
      const title = `${product.name} - ${product.tagSize} - $${formatAmount(product.salePrice)}`;

      setMetaTags({
        description, image, url, title,
      });
    }
  }, [product, setMetaTags]);

  if (!product) {
    return <Loader />;
  }

  return (
    <Layout
      desktop={<DesktopItem id={listingId} {...product} {...props} />}
      mobile={<MobileItem id={listingId} {...product} {...props} />} />
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer });

const mapDispatchToProps = { showQuestionModal, setMetaTags };

export default connect(mapStateToProps, mapDispatchToProps)(Item);