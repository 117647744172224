import { makeRequest, API_URL } from '../Fetch';

export function viewListing(listingId) {
  return makeRequest(API_URL + `listing/${listingId}/view`).setMethod('POST').send();
}

export function favoriteListing(listingId) {
  return makeRequest(API_URL + `listing/${listingId}/like`).setMethod('POST').send();
}

export function editListing(listingId, data) {
  return makeRequest(API_URL + `listing/${listingId}`, data).setMethod('PUT').send();
}

export function boostListing(listingId) {
  return makeRequest(API_URL + `listing/${listingId}/boost`).setMethod('POST').send();
}

export function deleteListing(listingId) {
  return makeRequest(API_URL + `listing/${listingId}`).setMethod('DELETE').send();
}