import { getDocument } from '../helpers/Firebase';
import * as types from './actionTypes';
import { setLoading } from './loadingActions';

export function loadMessages(uid) {
  return async (dispatch, getState) => {
    const { accountData: { messages } = [], accountType } = getState().userReducer;
    const messagesFormatted = await formatMessages(messages, uid, accountType);

    try {
      dispatch(setLoading(true));
      dispatch({
        type: types.LOAD_MESSAGES,
        messages: messagesFormatted,
      });
    } catch (e) {
      console.log(e);
      alert(e.message || e);
    }
    dispatch(setLoading(false));
  };
}

function formatMessages(messages = [], uid, accountType) {
  return new Promise((resolveA) => {
    const result = {};
    const promises = messages.map(({
      from, date, message, id, to, accountType: otherUserAccountType, type = 'message', listing,
    }) =>
      new Promise((resolveB) => {
        let url;
        const otherUserUid = from === uid ? to : from;

        if (otherUserAccountType === 2) {
          //account is not a user type
          url = `users/${otherUserUid}`;
        } else if (otherUserAccountType === 1 || otherUserAccountType === 0) {
          url = `sellers/${otherUserUid}`;
        }
        getDocument(url).then(({
          profile: {
            avatar, firstName, lastName,
          },
        }) => {
          if (from === uid && !result[to]) {
            result[to] = {
              avatar,
              name: `${firstName} ${lastName}`,
              uid: to,
              thread: [],
              threadMessages: '',
            };
          } else if (from !== uid && !result[from]) {
            result[from] = {
              avatar,
              name: `${firstName} ${lastName}`,
              uid: from,
              thread: [],
              threadMessages: '',
            };
          }

          if (from === uid) {
            result[to].threadMessages += message + ' ';
            result[to].thread.push({
              date, message, id, from, to, type, listing,
            });
          } else {
            result[from].threadMessages += message + ' ';
            result[from].thread.push({
              date, message, id, from, to, type, listing,
            });
          }
          resolveB();
        });
      }),
    );

    Promise.all(promises).then(() => {
      resolveA(result);
    });
  });
}