import { getFirebase } from '../../modules/firebase';

export function pushToArray(docPath, field, data) {
  return new Promise((resolve) => {
    const firebase = getFirebase();
    var docRef = firebase.firestore().doc(docPath);

    docRef.update({ [field]: firebase.firestore.FieldValue.arrayUnion(data) }).then(resolve);
  });
}

export function removeFromArray(docPath, field, data) {
  return new Promise((resolve) => {
    const firebase = getFirebase();
    var docRef = firebase.firestore().doc(docPath);

    docRef.update({ [field]: firebase.firestore.FieldValue.arrayRemove(data) }).then(resolve);
  });
}

export function uploadImage(image, path) {
  return new Promise((resolve) => {
    const firebase = getFirebase();
    var storageRef = firebase.storage().ref();
    var metadata = { contentType: image.type };

    storageRef.child(path).put(image, metadata).then((snapshot) => {
      snapshot.ref.getDownloadURL().then(function (downloadURL) {
        return resolve(downloadURL);
      });
    });
  });
}

export function watchPropertyFromDatabase(propertyPath, callback) {
  const firebase = getFirebase();

  if (firebase) {
    const propertyRef = firebase.database().ref(propertyPath);

    propertyRef.on('value', (snapshot) => {
      callback(snapshot.val());
    });
  }
}

export function setPropertyOnDatabase(propertyPath, data) {
  const firebase = getFirebase();

  if (firebase) {
    const propertyRef = firebase.database().ref(propertyPath);
    return propertyRef.set(data);
  }
}

export function getFromDatabase(path) {
  return new Promise((resolve, reject) => {
    const firebase = getFirebase();

    firebase.database().ref(path).once('value').then((res) => {
      if (res.exists) {
        return resolve(res.val());
      } else {
        return reject(null);
      }
    });
  });
}

export function authStateChanged(callback) {
  const firebase = getFirebase();
  let called = false;

  if (firebase) {
    firebase.auth().onAuthStateChanged((user) => {
      called = true;
      callback(user);
    });
    setTimeout(() => {
      if (!called) {
        callback();
      }
    }, 3000);
  }
}

export function signInWithToken(token) {
  const firebase = getFirebase();

  if (firebase) {
    return firebase.auth().signInWithCustomToken(token);
  }
}

export function watchDocumentChanges(docPath, callback) {
  const firebase = getFirebase();

  if (firebase) {
    var docRef = firebase.firestore().doc(docPath);

    docRef.onSnapshot(function (doc) {
      if (doc.exists) {
        callback(doc.data());
      }
    });
  }
}

export function watchCollectionChanges(collectionPath, callback, sendRef = false, queries) {
  const firebase = getFirebase();

  if (firebase) {
    var docRef = firebase.firestore().collection(collectionPath);

    if (queries) {
      if (!Array.isArray(queries[0])) {
        queries = [queries];
      }

      for (var index in queries) {
        const query = queries[index];
        docRef = docRef.where(...query);
      }
    }
    docRef.onSnapshot(function (collection) {
      let data = [];

      if (!collection.empty) {
        collection.docs.forEach((document) => {
          if (document.exists) {
            data.push({
              ...document.data(),
              __id: document.id,
              ref: sendRef ? document.ref : null,
            });
          }
        });
      }
      callback(data);
    });
  }
}


export async function getDocument(docPath) {
  const firebase = getFirebase();

  if (firebase) {
    var docRef = firebase.firestore().doc(docPath);
    const doc = await docRef.get();

    if (!doc.exists) {
      return null;
    }

    const data = doc.data();

    if (data) {
      return {
        ...data,
        __id: docRef.id,
      };
    }
  }
}

export async function getCollection(collectionPath, queries) {
  const firebase = getFirebase();

  if (firebase) {
    let data = [];
    var docRef = firebase.firestore().collection(collectionPath);

    if (queries) {
      if (!Array.isArray(queries[0])) {
        queries = [queries];
      }

      for (var index in queries) {
        const query = queries[index];
        docRef = docRef.where(...query);
      }
    }

    const documents = await docRef.get();

    documents.forEach((document) => {
      if (document.exists) {
        data.push({
          ...document.data(),
          __id: document.id,
        });
      }
    });
    return data;
  }
}

export async function setProperty(collectionPath, value) {
  try {
    const firebase = getFirebase();

    if (firebase) {
      var docRef = firebase.firestore().doc(collectionPath);
      await docRef.set(value);
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export function updateDocument(docPath, data) {
  const firebase = getFirebase();

  if (firebase) {
    return firebase.firestore().doc(docPath).update(data);
  }
}


export function createAccount(email, password) {
  const firebase = getFirebase();

  if (firebase) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
}

export function getCurrentUser() {
  return new Promise((resolve) => {
    const firebase = getFirebase();

    if (firebase) {
      if (firebase.auth().currentUser) {
        return resolve(firebase.auth().currentUser);
      }

      const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
        resolve(currentUser);
        unsubscribe();
      });
    }
  });
}

export async function reauthenticate(password) {
  const firebase = getFirebase();

  if (firebase) {
    const user = await getCurrentUser();
    let credential = firebase.auth.EmailAuthProvider.credential(
      user.email, password);
    return user.reauthenticateWithCredential(credential);
  }
}

export function sendEmailResetLink(email) {
  const firebase = getFirebase();

  if (firebase) {
    firebase.auth().signOut();
    return firebase.auth().sendPasswordResetEmail(email);
  }
}


export function signInWithEmailAndPassword(email, password) {
  const firebase = getFirebase();

  if (firebase) {
    return firebase.auth().signInWithEmailAndPassword(email?.trim(), password);
  }
}

export function signOut() {
  const firebase = getFirebase();
  return firebase.auth().signOut();
}

export async function getAuthToken() {
  const user = await getCurrentUser(true);

  if (user) {
    return user.getIdToken(true);
  }
}