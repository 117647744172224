import styled from 'styled-components';

export const MessageButton = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.438vw;
  color: #464855;
  letter-spacing: 0;
`;

export const ArrowImage = styled.img`
  margin-left: 1.188vw;
`;

export const Container = styled.div`
  background-color: #F3F0EB;
  display: flex;
  padding: 5.938vw 6.875vw 6.875vw 8.438vw;
  flex-direction: column;
`;

export const BrideImage = styled.img`
  width: 29.94vw;
  height: 29.94vw;
  object-fit:cover;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: 4.000vw;
  position: relative;
`;

export const HeadingColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileName = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 3.875vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 38px;
`;

export const Location = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.375vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 38px;
  margin-bottom: 2.750vw;
`;

export const AboutTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 3.000vw;
  color: #343538;
  letter-spacing: 0.74px;
  line-height: 38px;
`;

export const AboutSummary = styled.div`
  margin-top: 2.313vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 1.125vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 35px;
  width: 30.06vw;
`;

export const Body = styled.div`
  background-color: white;
  margin-top: -3.875vw;
  padding-top: 8.500vw;
`;

export const BodyTitle = styled.div`
  margin-left: 4.000vw;
  font-family: BigCaslon-Regular;
  font-size: 2.813vw;
  color: #464855;
  letter-spacing: 0.36px;
`;

export const ItemsContainer = styled.div`
  margin-top: 4.313vw;
  margin-left: 4.000vw;
  margin-bottom: 7.688vw;
`;

export const Item = styled.div`

`;

export const Divider = styled.div`
  height: 1px;
  background-color: #979797;
  margin: 0 4.000vw;
`;

export const TestimoniesContainer = styled.div`
  margin-top: 4.375vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5.750vw;
  margin-left: calc(3.500vw / 2);
  margin-right: calc(3.500vw / 2);
`;

export const TestimoniesTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 2.375vw;
  color: #212224;
  letter-spacing: 0.95px;
  text-align: center;
  line-height: 46px;
`;

export const TestimoniesSubtitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 1.188vw;
  color: #A3A5AD;
  letter-spacing: 0;
  text-align: center;
  line-height: 29px;
  width: 37.00vw;
  margin-top: 0.5000vw;
`;

export const Testimonies = styled.div`
  display: flex;
  margin-top: 4.875vw;
`;
