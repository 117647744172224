import React from 'react';
import Row from '../Row';
import {
  Circle, Fill, Label,
} from './styled.js';

function RadioButton({
  label, update, value, style, ...other
}) {
  return (
    <Row onClick={() => update(!value)} style={{
      justifyContent: 'center', alignItems:'center', cursor: 'pointer', ...style,
    }} {...other}>
      <Circle>
        {value === true && <Fill />}
      </Circle>
      <Label>{label}</Label>
    </Row>
  );
}

export default RadioButton;