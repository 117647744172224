import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { connect } from 'react-redux';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import isEqual from 'lodash.isequal';
import { editCartItem, createPurchase } from '../../../actions/checkoutActions';
import * as apiHelpers from '../../../helpers/API';
import {
  insuranceAmount, getDiscount, handleStripeSubmit,
} from '../../../helpers/Checkout';
import { Container } from './styled';
import Heading from './Heading';
import Page1 from './Page1';
import Page2 from './Page2';
var debounce = require('lodash.debounce');

function Checkout({
  editCartItem, createPurchase, shippingCost,
  setShippingDetails, checkoutItems, products,
  accountData, offers,
}) {
  const [findingShippingPrice, setFindingShippingPrice] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [form, setForm] = useState({});
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { profile: { email, phoneNumber } = {} } = accountData || {};
  const {
    shippingCity,
    shippingStreet1,
    shippingLastName,
    shippingFirstName,
    shippingState,
    shippingZip,
    shippingStreet2,
  } = form;

  const getTotal = useCallback((addInsurance) => {
    const _items = Object.keys(checkoutItems).map((id) => {
      const hasOffer = offers.find(({ listing, status }) => id === listing && status === 'accepted');

      if (hasOffer) {
        const product = {
          ...products[id],
          salePrice: hasOffer.amount * 100,
        };
        products[id] = product;
        return product;
      } else {
        return products[id];
      }
    }).filter((exists) => !!exists);
    const newForm = { ...form, products };

    if (!isEqual(form, newForm)) {
      setForm(newForm);
    }

    let _total = 0;

    if (form.purchaseInsurance && addInsurance) {
      _total += insuranceAmount;
    }

    _total = shippingCost ? _total + parseFloat(shippingCost * 100) : _total;

    if (!isEqual(items, _items)) {
      setItems(_items);
    }
    return _total;
  }, [checkoutItems, form, offers, products, shippingCost]);


  useEffect(() => {
    const _total = getTotal(true);

    if (_total - discount !== total) {
      setTotal(_total - discount);
    }
  }, [checkoutItems, discount, form.purchaseInsurance, getTotal, products, shippingCost]);

  useEffect(() => {
    const _city = shippingCity;
    const _state = shippingState;
    const _zip = shippingZip;
    const _street1 = shippingStreet1;
    const _firstName = shippingFirstName;
    const _lastName = shippingLastName;
    const _street2 = shippingStreet2;

    if (_city && _state && _zip && _street1 && _firstName && _lastName) {
      updateShippingPriceThrottled.current(
        _city,
        _street1,
        _street2,
        _state,
        _zip,
        _firstName,
        _lastName,
      );
    }
  }, [
    shippingCity,
    shippingFirstName,
    shippingLastName,
    shippingState,
    shippingStreet1,
    shippingStreet2,
    shippingZip,
  ]);

  const updateShippingPriceThrottled = useRef(
    debounce((city, street1, street2, state, zip, firstName, lastName) => {
      const address = {
        city,
        state,
        street1,
        street2,
        zip,
      };
      const name = `${firstName} ${lastName}`;
      setFindingShippingPrice(true);
      apiHelpers
        .getShippingCostFromAddress(address, name, email, phoneNumber)
        .then(({
          rate, shipmentId, rateId,
        }) => {
          setFindingShippingPrice(false);
          setShippingDetails(rate, shipmentId, rateId);
        });
    }, 1300),
  );

  const updateForm = useCallback(
    (key, value) => {
      setForm({
        ...form,
        [key]: value,
      });
    },
    [form],
  );

  const applyDiscount = useCallback(() => {
    const _total = getTotal();

    getDiscount(_total, form.couponCode).then((_discount) => {
      setDiscount(_discount);
    });
  }, [form.couponCode, getTotal]);

  const handleSubmit = useCallback(async () => {
    const { paypalID } = form;
    const stripeToken = await handleStripeSubmit(elements, stripe);

    createPurchase({
      ...form,
      stripeToken,
      paypalID,
      total,
    });
  }, [elements, stripe, createPurchase, form, total]);

  let content;

  switch (page) {
    case 0:
      content = (
        <Page1
          discount={discount}
          getDiscount={applyDiscount}
          updateForm={updateForm}
          form={form}
          items={items}
          editCartItem={editCartItem}
          shippingCost={shippingCost}
        />
      );
      break;
    case 2:
    case 1:
      content = (
        <Page2
          discount={discount}
          findingShippingPrice={findingShippingPrice}
          page={page}
          total={total}
          items={items}
          shippingCost={shippingCost}
          form={form}
          disabled={page === 2}
          updateForm={updateForm}
        />
      );
      break;
  }
  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();

        if (!items.length) {
          return alert('You must have items in your cart to continue');
        }

        if (page < 2) {
          setPage(page + 1);
        } else {
          handleSubmit();
        }
        return false;
      }}
    >
      <Heading
        changeIndex={
          items.length
            ? setPage
            : () => alert('You must have items in your cart to continue')
        }
        activeIndex={page}
      />
      <div style={{ display: 'flex' }}>{content}</div>
    </Container>
  );
}

const mapDispatchToProps = {
  editCartItem,
  createPurchase,
};

const mapStateToProps = (state) => ({
  ...state.shopReducer,
  ...state.userReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
