import React from 'react';
import Image from '../../../../ImageBasic';
import Divider from '../../../../Divider';
import Row from '../../../../Row';
import Column from '../../../../Column';
import EditSVG from './EditIcon.svg';
import BoostSVG from './BoostIcon.svg';
import BoostSVGDisabled from './BoostIconDisabled.svg';
import DressAnalytics from './DressAnalytics';
import {
  ItemContainer,
  ItemHeader,
  ItemTitle,
  ItemPicture,
  Label,
  ItemFooterHeading,
  ItemFooterContent,
  Unpublished,
} from './styled';

function Item(props) {
  const {
    disabled,
    selected,
    edit = () => {},
    boost,
    name,
    __id,
    photos: [image],
    boostDate,
    accountType,
    views = [],
    likes = 0,
    showBoost,
    style,
    unpublished,
  } = props;
  return (
    <ItemContainer
      disabled={disabled}
      selected={selected && !disabled}
      onClick={() => !disabled && edit(__id)} style={style}
    >
      <ItemHeader style={unpublished ? { padding: 0 } : null}>
        {unpublished ? <Unpublished>Unpublished</Unpublished> : null}
        <ItemTitle>{name}</ItemTitle>
      </ItemHeader>
      <ItemPicture>
        <img src={image} />
      </ItemPicture>
      <Column>
        <Row style={{
          height: 57, justifyContent: 'center', alignItems: 'center',
          width: '100%',
        }}
        >
          {edit && <Row style={{ flex: 1, justifyContent: 'center' }}>
            <Image style={{ cursor: 'pointer' }} src={EditSVG} />
            <Label style={{ margin: 20, cursor: 'pointer' }}>Edit</Label>
          </Row>
          }
          {(accountType === 1 && showBoost) && (
            <>
              <Divider vertical />
              <Row style={{ flex: 1, justifyContent: 'center' }}>
                <Image onClick={() => {
                  if (!boostDate) {
                    boost(__id);
                  } else {
                    alert('This listing has already been boosted');
                  }
                }} src={boostDate ? BoostSVGDisabled : BoostSVG}
                />
                <Label style={{ margin: 20, color: boostDate ? '#E1CFCD' : '#FE95A2' }}>Boost</Label>
              </Row>
            </>
          )}
        </Row>
        <Divider />
        <Row style={{
          marginBottom: 12,
          justifyContent:'space-around', width:'100%', flex: 1,
        }}
        >
          <Column style={{
            justifyContent: 'center', alignItems:'center', flex:1,
          }}
          >
            <ItemFooterHeading>{views.length}</ItemFooterHeading>
            <ItemFooterContent>Impressions</ItemFooterContent>
          </Column>
          <Divider style={{ height: 'calc(100% + 24px)', transform:'translateY(-12px)' }} vertical />
          <Column style={{
            justifyContent: 'center', alignItems:'center', flex: 1,
          }}
          >
            <ItemFooterHeading>{likes}</ItemFooterHeading>
            <ItemFooterContent>Likes</ItemFooterContent>
          </Column>
        </Row>
        <Divider />
        <DressAnalytics views={views} />
      </Column>
    </ItemContainer>
  );
}

export default Item;
