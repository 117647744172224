import React, { useState } from 'react';
import { Container, BoxStyle } from './styled';

const map = {
  'What condition is your dress in?': {
    'New/never worn':{
      'Was the dress purchases less than 2 years ago?': {
        'Yes':'Price dress at 75% of retail', 'No, 3-5 years ago':'Price dress at 50% of retail', 'No, 6+ years ago':'Price dress at 40% of retail price',
      },
    } ,
    'Good/worn once':{
      'Was it cleaned?': {
        'Yes': {
          'Was the dress purchases less than 2 years ago?': {
            'Yes':'Price dress at 50% of retail', 'No, 3-5 years ago':'Price dress at 40% of retail', 'No, 6+ years ago':'Price dress at 30% of retail price',
          },
        },
        'No':'Price dress at 30% or less of retail price',
      },
    },
    'Fair/poor, need cleaning or repair': 'Price dress at 30% or less of retail price',
  },
};

function Row({
  items, active, setActive,
}) {
  return (<div style={{ display: 'flex', marginTop: 20 }}>
    {items.map((text, index) => <Box setActive={setActive} active={active === text} style={{ margin: items.length === 1 ? 'unset' : index === 0 ? '0 10px 0 0' : index === items.length - 1 ? '0 0 0 10px' : '0 10px' }} text={text} />)}
  </div>);
}

function Box({
  text = '', active, style, setActive = () => {},
}){
  return (
    <BoxStyle onClick={() => setActive(text)} active={active} style={{
      height: 'auto', width: '100%', ...style,
    }}>
      {text}
    </BoxStyle>
  );
}

function buildTree(_obj, tree = [], setActive, active, index = 0) {
  if (typeof(_obj) === 'string') {
    tree.push(<Box style={{
      marginTop: 20, backgroundColor: '#CF7E89', width:'unset',
    }} text={_obj}/>);
    return tree;
  }

  const statement = Object.keys(_obj)[0];

  tree.push(<Box style={{ marginTop: 20, width: 'unset' }} text={statement}/>);

  function _setActive(key) {
    const _active = active.slice(0, (index + active.length ) - active.length );

    if (active[index] === key) {
      return setActive(_active);
    }

    Object.keys(_obj[statement]).forEach((_key) => {
      if (_key === key){
        _active.push(_key);
      }
    });
    setActive(_active);
  }


  if (typeof(_obj[statement]) === 'object') {
    const choices = Object.keys(_obj[statement]);
    const activeTree = choices.find((_key) => active[index] === _key);

    if (activeTree) {
      tree.push(<Row setActive={_setActive} active={activeTree} items={choices} />);
      return buildTree(_obj[statement][activeTree], tree, setActive, active, index + 1);
    } else {
      tree.push(<Row setActive={_setActive} items={choices} />);
      return tree;
    }
  }
}

function PricingDiagram() {
  const [active, setActive] = useState([]);
  console.log('active', active);

  return (
    <Container>
      {buildTree(map, [], setActive, active)}
    </Container>
  );
}

export default PricingDiagram;