import React, { } from 'react';
import moment from 'moment';
import {
  ScrollContainer, Container, ItemContainer, Name, LastMessage, Time, Indicator,
} from './styled';
import SearchBar from './SearchBar';

function getLastMessage(thread, name) {
  const lastMessage = thread.slice(-1)[0];

  if (lastMessage.type === 'offer') {
    return `Hi ${name}! You have an offer on your dress for $${thread.message}. Would you like to accept or decline this offer?`;
  }
}

function MessageList({
  messages, currentMessageId, selectMessage,
  searchResults, setSearch, profile,
}) {
  return (
    <Container>
      <SearchBar update={setSearch} messages={messages} />
      <ScrollContainer>
        {
          Object.values(searchResults).map(({
            name, avatar, thread,
          }, index) => {
            if (!thread || !thread.length) {
              return <div />;
            }

            const active = thread.find(({ id }) => id === currentMessageId);
            return (
              <ItemContainer onClick={() => selectMessage(thread.slice(-1)[0])} active={active} key={index}>
                <img src={avatar} />
                <div>
                  <Name>{name}</Name>
                  <LastMessage>{getLastMessage(thread, profile.firstName)}</LastMessage>
                </div>
                <Time>{moment(thread.slice(-1)[0].date).fromNow()}</Time>
                {active && <Indicator />}
              </ItemContainer>
            );
          })
        }
      </ScrollContainer>
    </Container>
  );
}

export default MessageList;
